<template>
  <div>
    <v-btn @click="toggleFilterOnXs()" class="search-bar-toggle d-md-none"
      ><v-icon>mdi-dots-horizontal</v-icon></v-btn
    >
    <v-sheet
      :class="['books-filters-parent', showFilterOnXs ? 'show' : '']"
      outlined
    >
      <div
        :class="['px-6 pt-2 d-flex justify-end align-center']"
        style="height: 30px"
      >
        <v-checkbox
          v-if="!mlState.libraryParent"
          v-model="fromSubLibrary"
          dense
          label="Return from Sub Library"
          class="mt-3 mr-2"
        ></v-checkbox>
        <v-dialog v-if="selectedBooks.length > 0" max-width="950">
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              bordered
              left
              :content="selectedBooks.length"
              overlap
              class="float-right"
            >
              <v-btn
                tile
                x-small
                color="primary"
                class="px-4 ml-2"
                v-bind="attrs"
                v-on="on"
              >
                Selected Books
              </v-btn>
            </v-badge>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="primary" dark
                >Selected books to return</v-toolbar
              >
              <smooth-scrollbar>
                <div class="selectedBooksRow">
                  <v-row class="pt-6 px-2 mx-0">
                    <BookCard
                      v-for="book in selectedBooksDetails"
                      :key="book._id"
                      :book="book"
                      type="return"
                      sm="6"
                      xs="12"
                      md="4"
                      lg="4"
                      cols="12"
                    />
                  </v-row>
                </div>
              </smooth-scrollbar>
              <v-card-actions class="justify-end pb-4">
                <v-btn tile color="primary" @click="resetSelectedBooks()"
                  >Clear all</v-btn
                >
                <v-btn tile color="primary" @click="dialog.value = false"
                  >Close</v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
        <v-btn tile x-small @click="resetFilter()" class="ml-2">
          Reset
        </v-btn>
        <v-btn tile x-small @click="toggleFilterOnXs()" class="ml-2 d-md-none"
          >Hide</v-btn
        >
      </div>
      <div :class="['d-flex', 'align-center']">
        <v-row class="mx-0 px-4 pt-2">
          <v-col
            :xl="fromSubLibrary ? 10 : 7"
            :md="fromSubLibrary ? 10 : 7"
            sm="12"
            cols="12"
            class="px-0 py-0 my-0"
          >
            <v-autocomplete
              v-if="!fromSubLibrary"
              v-model="member"
              clearable
              :items="membersData"
              :loading="isMemberLoading"
              :search-input.sync="memberSearchKey"
              :filter="filterMembers"
              item-text="name"
              item-value="_id"
              label="Select a member to return books"
              class="mx-2 mt-0"
              dense
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title
                    v-html="`# ${data.item.memberNumber} ${data.item.name}`"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <v-select
              v-else
              dense
              class="mx-2 mt-0"
              v-model="subLibrary"
              :loading="!isSubLibrariesLoaded"
              :items="subLibraries"
              item-text="name"
              item-value="_id"
              label="Select a sub library to return books"
              clearable
            ></v-select>
          </v-col>
          <v-col
            v-if="!fromSubLibrary"
            xl="3"
            md="3"
            sm="12"
            cols="12"
            class="px-0 py-0 my-0"
          >
            <v-select
              dense
              class="mx-2 mt-0"
              v-model="distributionType"
              :items="['General', 'Balavedhi', 'Reference', 'E-Reader']"
              label="Distribution Type"
            ></v-select>
          </v-col>
          <v-col xl="2" md="2" sm="12" cols="12" class="px-0 py-0 my-0">
            <div class="mx-2">
              <v-dialog max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="success"
                    width="100%"
                    small
                    tile
                    v-bind="attrs"
                    v-on="on"
                    :disabled="selectedBooks.length === 0"
                  >
                    Return
                  </v-btn>
                </template>
                <template v-slot:default="dialog">
                  <v-card v-if="selectedBooks.length > 0">
                    <v-toolbar dense color="primary" dark
                      >Confirm return ?</v-toolbar
                    >
                    <div class="text--primary py-6 px-4">
                      Are you sure to return the following book{{
                        selectedBooks.length > 1 ? "s" : ""
                      }}
                    </div>
                    <div class="px-4 pt-2 pb-4">
                      <v-menu
                        ref="returnOnMenu"
                        v-model="returnOnMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            v-model="returnDateText"
                            class="mx-2 my-0"
                            label="Return Date"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          no-title
                          v-model="returnDate"
                          min="1950-01-01"
                          scrollable
                          @change="$refs.returnOnMenu.save(returnDate)"
                        ></v-date-picker>
                      </v-menu>
                      <v-menu
                        ref="returnOnTimeMenu"
                        v-model="returnOnTimeMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            v-model="returnTimeText"
                            class="mx-2 my-0"
                            label="Return Time"
                            append-icon="mdi-clock-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          no-title
                          v-model="returnTime"
                          scrollable
                          format="ampm"
                          landscape
                          @change="$refs.returnOnTimeMenu.save(returnTime)"
                        ></v-time-picker>
                      </v-menu>
                    </div>
                    <smooth-scrollbar>
                      <div style="max-height: 180px">
                        <v-row no-gutters class="px-4">
                          <div>
                            <v-chip
                              v-for="book in selectedBooksDetails"
                              :key="book._id"
                              class="mr-1 mb-2"
                            >
                              {{ book.name }}
                            </v-chip>
                          </div>
                        </v-row>
                      </div>
                    </smooth-scrollbar>
                    <v-card-actions class="justify-end pb-4">
                      <v-btn
                        small
                        tile
                        color="primary"
                        @click="dialog.value = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        @click="initiateReturn(() => (dialog.value = false))"
                        small
                        tile
                        color="success"
                        >Continue</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>
          </v-col>
        </v-row>
      </div>
      <div :class="['d-flex', 'align-center']">
        <v-row class="mx-0 px-4">
          <v-col xl="4" md="4" sm="12" cols="12" class="px-0 py-0 my-0">
            <v-text-field
              v-model="searchKey"
              clearable
              dense
              class="mx-2"
              append-icon="mdi-magnify"
              label="Search"
            ></v-text-field>
          </v-col>

          <v-col xl="2" md="2" sm="12" cols="12" class="px-0 py-0 my-0">
            <v-select
              dense
              class="mx-2"
              :items="searchByFilters"
              item-text="name"
              item-value="_id"
              v-model="searchBy"
              label="Search By"
              clearable
            ></v-select>
          </v-col>

          <v-col xl="2" md="2" sm="12" cols="12" class="px-0 py-0 my-0">
            <vc-data-autocomplete
              :disabled="
                distributionType === 'E-Reader' ||
                  distributionType === 'Reference'
              "
              v-model="type"
              api="/book/type"
              map-value="name"
              emit-on-search
              clearable
              class="mx-2"
              dense
              label="Category"
            />
          </v-col>

          <v-col xl="2" md="2" sm="12" cols="12" class="px-0 py-0 my-0">
            <v-select
              dense
              class="mx-2"
              v-model="orderBy"
              :items="searchByOrder"
              item-text="name"
              item-value="orderBy"
              label="Order by"
            ></v-select>
          </v-col>

          <v-col xl="2" md="2" sm="12" cols="12" class="px-0 py-0 my-0">
            <v-menu
              ref="distributedDateMenu"
              v-model="distributedDateMenu"
              :close-on-content-click="false"
              :return-value.sync="distributedDates"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hint="Select a date range"
                  dense
                  class="mx-2"
                  clearable
                  v-model="distributedDateRangeText"
                  label="Distributed date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :append-icon="
                    distributedDateRangeText &&
                    distributedDateRangeText.includes('~')
                      ? ''
                      : 'mdi-calendar-range'
                  "
                ></v-text-field>
              </template>
              <v-date-picker
                :max="distributedFilterMaxDate"
                no-title
                v-model="distributedDates"
                :reactive="true"
                range
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="distributedDateMenu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.distributedDateMenu.save(distributedDates)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import { useReturnFilter } from "@/modules";
import BookCard from "@/components/BookCard";

export default {
  setup: useReturnFilter,
  props: ["loadNextPage"],
  components: {
    BookCard
  }
};
</script>
