<template>
  <div style="height: 100%" class="return">
    <ReturnFilters :loadNextPage="loadNextPage" />
    <v-progress-linear v-if="isLargeLoading" indeterminate></v-progress-linear>
    <v-container
      v-if="fromSubLibrary && !selectedsubLibrary"
      class="text-center py-10 hide-following-divs"
    >
      Select a Sub Library
    </v-container>
    <v-container v-if="noRecordsFound" class="text-center py-10">
      No distributed books found.
    </v-container>
    <div class="books-listing-parent" ref="scrollable" v-bar>
      <div>
        <v-container
          :class="[isEmpty ? 'd-none' : '', !loadedAll ? 'pull-up-margin' : '']"
        >
          <v-row>
            <BookCard
              v-for="book in data"
              :key="book._id"
              :book="book"
              type="return"
              sm="6"
              xs="12"
              md="4"
              lg="4"
              cols="12"
              :fromSubLibrary="fromSubLibrary"
            />
          </v-row>
        </v-container>
        <SkeltonLoader v-if="!loadedAll" />
      </div>
    </div>
  </div>
</template>

<script>
import { useReturn } from "@/modules";
import BookCard from "@/components/BookCard";
import SkeltonLoader from "@/components/SkeltonLoader";
import ReturnFilters from "@/components/ReturnFilters";

export default {
  setup: useReturn,
  components: {
    BookCard,
    SkeltonLoader,
    ReturnFilters
  }
};
</script>
